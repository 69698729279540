import React, { useEffect } from "react";
import "./App.css";
import logo from "./assets/TurfLogo.png";
import playstoreIcon from "./assets/playstore.png";
import appstoreIcon from "./assets/appstore.png";
import game1 from "./assets/game1.jpg";
import game2 from "./assets/game2.jpg";
import game3 from "./assets/game3.jpg";
import game4 from "./assets/game4.jpg";
import game5 from "./assets/game5.jpg";
import game6 from "./assets/game6.jpg";
import game7 from "./assets/game7.jpg";
import game8 from "./assets/game8.jpg";
import game9 from "./assets/game9.jpg";
import game10 from "./assets/game10.jpg";


function App() {

  useEffect(() => {

  }, []);

  const navigatetoPlaystore = () =>{
    window.open('https://play.google.com/store/apps/details?id=com.avelator.playonpitch&pcampaignid=web_share');
  }

  return (
    <div>
      <div className="landing_body">

        <div className="landing_content">
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="icons">
            <img src={playstoreIcon} onClick={()=>navigatetoPlaystore()} />
            {/* <img src={appstoreIcon} /> */}
          </div>
          <div className="landing_data"> Join our turf club</div>
          <div className="landing_data1">Having a bad day? Catch some waves! Enjoy a place with perfect surfing conditions and our professional trainers.</div>
        </div>


        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-100" src={game1} alt="First slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={game2} alt="Second slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={game3} alt="Third slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={game4} alt="Third slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={game5} alt="Third slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={game6} alt="Third slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={game7} alt="Third slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={game8} alt="Third slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={game9} alt="Third slide" />
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src={game10} alt="Third slide" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
